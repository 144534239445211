<template>
  <v-toolbar color="blue darken-4" dark flat>
    <v-tabs v-model="tab" align-with-title>
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <template v-for="({ name, link, rol }, i) in itemsHeader">
        <v-tab v-if="showTab(rol)" :to="link" :key="i" link> {{ name }}</v-tab>
      </template>
    </v-tabs>
  </v-toolbar>
</template>

<script>
  import { mapState } from "vuex";


export default {
  name: 'BueeHeader',
  props: {
    itemsHeader: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    tab: null,
    userRoles: {},
  }),
  computed: {
    ...mapState(["auth"]),
  },
  created() {
    this.userRoles = this.auth.roles;
  },

  methods:{
    showTab(rol) {
      if (Array.isArray(rol)) {
        return rol.some(r => this.userRoles.includes(r));
      } else {
        return this.userRoles.includes(rol);
      }
    }
  }
}
</script>

<style scoped>
.v-sheet {
  height: 38px !important;
}

::v-deep .v-toolbar__content {
  height: 35px !important;
}
</style>