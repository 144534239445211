<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Diego Alexander Villalba Cely                                 ###### -->
<!-- ###### @date: Octubre 2023                                                    ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <section class="subHeader-content">
    <BlueHeader :itemsHeader="itemsHeader" />

    <!-- Seccion para cargar el contenido de la navegación de las rutas hijas del submenu-->
    <v-slide-y-transition mode="out-in">
      <router-view></router-view>
    </v-slide-y-transition>


  </section>
</template>

 <!-- #################################################################################### -->
 <!-- ###### Sección de Scripts                                                     ###### -->
 <!-- #################################################################################### -->
<script>
import { Role } from "@/router/role.js";
import BlueHeader from "../../../../components/BlueHeader.vue";

export default {
  name: 'DispensationMain',
  components: { BlueHeader },

  data: () => ({
    tab: null,
    roles: Role,
    userRoles: {},
    itemsHeader: [
      {
        name: 'Ambulatorio',
        link: { name: 'modules.pharmaceuticalServicesMain.dispensation.ambulatorio' },
        rol: Role.ServiciosFarmaceuticos_Dispensacion_Ambulatorio,
      },
      {
        name: 'Intrahospitalario',
        link: { name: 'modules.pharmaceuticalServicesMain.dispensation.intrahospitalario' },
        rol: Role.ServiciosFarmaceuticos_Dispensacion_Intrahospitalario,
      },
      {
        name: 'Domicilios / Entregas programadas',
        link: { name: 'modules.pharmaceuticalServicesMain.dispensation.solicitud' },
        rol: Role.ServiciosFarmaceuticos_Dispensacion_Solicitud,
      },
    ]
  }),
}
</script>

 <!-- #################################################################################### -->
 <!-- ###### Sección de Styles                                                      ###### -->
 <!-- #################################################################################### -->
<style scoped>
.subHeader-content {
  width: 100%;
}
</style>